import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from "../app/guard/auth.guard";
const routes: Routes = [
  {
    path: '',
    redirectTo: 'bestellen',
    pathMatch: 'full'
  },
  {
    path: 'home'
    , canActivate: [AuthGuard]
    , loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'kontakt',
    loadChildren: () => import('./kontakt/kontakt.module').then( m => m.KontaktPageModule)
  },

  {
    path: 'profil',
    canActivate: [AuthGuard],
    loadChildren: () => import('./profil/profil.module').then( m => m.ProfilPageModule)
  },
  {
    path: 'bestellen',
    loadChildren: () => import('./bestellen/bestellen1/bestellen1.module').then( m => m.Bestellen1PageModule)
  },
  {
    path: 'bestellen2',
    loadChildren: () => import('./bestellen/bestellen2/bestellen2.module').then( m => m.Bestellen2PageModule)
  },

  {
    path: 'bezahlen',
    loadChildren: () => import('./bezahlen/bezahlen.module').then( m => m.BezahlenPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'registrieren',
    loadChildren: () => import('./auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'konto',
    loadChildren: () => import('./auth/konto/konto.module').then( m => m.KontoPageModule)
  },
  {
    path: 'hilfe',
    loadChildren: () => import('./hilfe/hilfe.module').then( m => m.HilfePageModule)
  },
  {
    path: 'kontrollen',
    canActivate: [AuthGuard],
    loadChildren: () => import('./kontrollen/kontrollen.module').then( m => m.KontrollenPageModule)
  },
  {
    path: 'zahlungsfehler',
    loadChildren: () => import('./zahlungsfehler/zahlungsfehler.module').then( m => m.ZahlungsfehlerPageModule)
  },
  {
    path: 'offline',
    loadChildren: () => import('./offline/offline.module').then( m => m.OfflinePageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
