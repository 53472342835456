import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { AlertController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  constructor(public swUpdate: SwUpdate, public alertController: AlertController) {

    //console.log("Update Service");
    this.assignUpdates();
    if (swUpdate.isEnabled) {
      //console.log("Update Service enabled");

      setInterval(() => {
        this.checkUpdate();
      } , 1000 * 60);

    }
    else{
      console.log("Update Service disabled");
    }
  }

  public checkUpdate(): void{
    //console.log("check for update");
    this.swUpdate.checkForUpdate().then( value =>{
      //console.log("update checker");
      //console.log(value);
    })
  }

  public assignUpdates(): void {
    if (this.swUpdate.isEnabled) {
      //console.log("subscripe updatews");
      this.swUpdate.available.subscribe(async () => {
        location.reload();
        /*
        const alert = await this.alertController.create({
          header: `App update!`,
          message: `Newer version of the app is available. It's a quick refresh away!`,
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
            }, {
              text: 'Refresh',
              handler: () => {
    
                window.location.reload();
              },
            },
          ],
        });
        await alert.present();
        */
      });
    }
  }
}
